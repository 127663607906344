import { Error } from '@twlvxtwlv/ui/src/components/shared/error'
import type { GetStaticPropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect, useState } from 'react'
import HomepagePage from '@twlvxtwlv/ui/src/components/pages/homepage/shared/homepage-page'

const CustomErrorPage = () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <>
      {isMounted ? (
        <HomepagePage>
          <Error statusCode={404} />
        </HomepagePage>
      ) : null}
    </>
  )
}

export const getStaticProps = async ({ locale = 'de' }: GetStaticPropsContext) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'navigation', 'footer'])),
    },
  }
}
export default CustomErrorPage
